import InfoParagraph from "./InfoParagraph";

const EricBackground = () => {
  const body = `Eric was born in Watsonville, CA and grew up in Santa Cruz County.  
    His family later moved to Lemoore, CA where Eric spent his High School years. Later on in life, he decided
    to move to Southern California where he attended California State
    University, Fullerton. During his tenure, he got a job as a Genius for
    Apple, but photography was always an interest of his. His computer and photography skills would eventually
    lead to an opportunity to work at a Nordstrom Photo Studio.  It was here that his life would be changed forever.`;
  return <InfoParagraph body={body} title="About Eric" />;
};

export default EricBackground;
