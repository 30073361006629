import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NavBar from "Components/NavBar/index";
import About from "Components/About/index";
import Venue from "Components/Venue";
import Registry from "Components/Registry";
import Landing from "Components/Landing";

import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <NavBar />
        </header>

        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/venue">
            <Venue />
          </Route>
          <Route path="/registry">
            <Registry />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
