import { makeStyles, Theme } from "@material-ui/core";

import SherlockOdin from "assets/images/sherlock_odin.png";
import LinkButton from "Components/LinkButton";

const useStyles = makeStyles((theme: Theme) => ({
  Registry: {
    display: "grid",
    gridTemplateRows: "repeat(3, auto)",
  },
  body: {
    padding: 10,
    display: "grid",
    gridTemplateRows: "75px auto",
    placeContent: "center",
    color: theme.palette.grey[700],
  },
  imageContainer: {
    placeSelf: "center",
  },
  image: {
    width: 700,
    borderRadius: 5,
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      width: 500,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: 400,
    },
  },
  title: {
    placeSelf: "center",
    fontWeight: 200,
    fontFamily: "'WindSong', serif",
    fontSize: "2.3rem",
  },
  text: {
    textAlign: "center",
    maxWidth: 700,
    letterSpacing: 3,
    lineHeight: 2,
  },
  buttonContainer: {
    placeSelf: "center",
    display: "grid",
    marginBottom: 100,
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(3, auto)",
      gridGap: 50,
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "repeat(3, auto)",
      gridGap: 15,
    },
  },
}));

const Registry = () => {
  const classes = useStyles();
  return (
    <section className={classes.Registry}>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          src={SherlockOdin}
          alt="Sherlock and Odin"
        />
      </div>
      <div className={classes.body}>
        <h3 className={classes.title}>Registry</h3>
        <p className={classes.text}>
          Please know that your presence at our wedding is present enough.
          However, for friends and family who have been asking for gift ideas,
          we've created some online registries here:
        </p>
      </div>
      <div className={classes.buttonContainer}>
        <LinkButton
          title="Amazon"
          link="https://www.amazon.com/wedding/share/ericandtheresa"
        />
        <LinkButton
          title="Target"
          link="https://www.target.com/gift-registry/gift/ericandtheresa"
        />
        <LinkButton
          title="Williams Sonoma"
          link="https://www.williams-sonoma.com/registry/xczc2msxdx/registry-list.html"
        />
      </div>
    </section>
  );
};

export default Registry;
