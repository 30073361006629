import { makeStyles, Tooltip, useMediaQuery } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Link, LinkProps } from "react-router-dom";
import cn from "classnames";
import MobileToolTip from "./MobileToolTip";
import ToolTipBody from "./ToolTipBody";

import { MOBILE_QUERY } from "AppContstants";

const useStyles = makeStyles(() => ({
  container: {
    padding: "15px 0px",
    minWidth: 150,
  },
  link: {
    height: 25,
    padding: 10,
    "&:hover": {
      background: grey[300],
      borderRadius: 5,
    },
  },
  diabledLink: {
    cursor: "pointer",
  },
  tooltip: {
    padding: 15,
    fontSize: "0.9rem",
    "& .tooltip-title": {
      fontSize: "0.8rem",
      paddingBottom: 6,
      textAlign: "center",
    },
  },
}));

type TooltipPlacement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

type CustomLinkProps = {
  text: String;
  disabled?: Boolean;
  tooltipPlacement?: TooltipPlacement;
};

type AllCustomLinkProps = CustomLinkProps & LinkProps;

const CustomLink = (props: AllCustomLinkProps) => {
  const { to, text, disabled = false, tooltipPlacement = "bottom" } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery(MOBILE_QUERY);

  function getToolTip() {
    if (isMobile) {
      return (
        <MobileToolTip>
          <span className={cn(classes.link, classes.diabledLink)}>{text}</span>
        </MobileToolTip>
      );
    } else {
      return (
        <Tooltip placement={tooltipPlacement} title={<ToolTipBody />}>
          <span className={cn(classes.link, classes.diabledLink)}>{text}</span>
        </Tooltip>
      );
    }
  }

  return (
    <div className={classes.container}>
      {disabled ? (
        getToolTip()
      ) : (
        <Link className={classes.link} to={to}>
          {text}
        </Link>
      )}
    </div>
  );
};

export default CustomLink;
