import { useEffect, useState } from "react";
import {
  makeStyles,
  ImageList,
  ImageListItem,
  useMediaQuery,
  Dialog,
  DialogContent,
  Paper,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import type { Theme } from "@material-ui/core";
import axios from "axios";
import { nanoid } from "nanoid";
import { observer } from "mobx-react";

import { useMainStore } from "Store/MainContext";
import { MOBILE_QUERY } from "AppContstants";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  paperImage: {
    display: "grid",
    gridTemplateColumns: "auto",
  },
  focusImage: {
    width: "75%",
    placeSelf: "center",
  },
}));

const ImageStrip = observer(() => {
  const [focusImage, setFocusImage] = useState({ open: false, image: null });
  const store = useMainStore();
  const classes = useStyles();
  const mediaQuery = useMediaQuery(MOBILE_QUERY);

  function handleFocusClick(image: any) {
    if (mediaQuery) return;
    setFocusImage({
      open: true,
      image,
    });
  }

  function handleClose() {
    setFocusImage({
      open: false,
      image: null,
    });
  }

  useEffect(() => {
    axios
      .get("/api/images")
      .then((res) => {
        store.addImages(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [store]);

  return (
    <div className={classes.root}>
      <Dialog open={focusImage.open} onClose={handleClose} maxWidth="xl">
        <DialogContent>
          <DialogActions>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogActions>
          <Paper className={classes.paperImage} elevation={10}>
            <img
              className={classes.focusImage}
              src={focusImage.image || "nope"}
              alt="Fail"
            />
          </Paper>
        </DialogContent>
      </Dialog>
      <ImageList
        className={classes.imageList}
        rowHeight={mediaQuery ? 300 : 600}
        cols={2.5}
      >
        {store.images.map((image, idx) => {
          return (
            <ImageListItem
              key={nanoid()}
              onClick={() => handleFocusClick(image)}
            >
              <img src={image} alt="Missing" />
            </ImageListItem>
          );
        })}
      </ImageList>
    </div>
  );
});

export default ImageStrip;
