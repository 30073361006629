import InfoParagraph from "./InfoParagraph";
const HowWeMet = () => {
  const body = `Theresa and Eric first met at a Photo Studio for Nordstrom. She was a stylist,
    he was a photographer. They started out as co-workers, but quickly grew to become close friends, then realizing
    they both had strong feelings for each other.  Eventually, their friendship grew to a relationship.  Though
    there were some challenges that came from dating a co-worker, in the end, it made the bond stronger and both
    of them grew to be better people for themselves and eachother. After just a short couple years, Eric would go on to ask
    Theresa to marry him.`;
  return <InfoParagraph body={body} title="How We Met" />;
};

export default HowWeMet;
