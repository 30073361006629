import { makeStyles } from "@material-ui/core";
import type { Theme } from "@material-ui/core";
import { observer } from "mobx-react";

import ImageStrip from "./ImageStrip";
import TheresaBackground from "./TheresaBackground";
import EricBackground from "./EricBackground";
import HowWeMet from "./HowWeMet";
import StoryOfProposal from "./StoryOfProposal";
import { MOBILE_QUERY } from "AppContstants";
import { grey } from "@material-ui/core/colors";

const FancyBreak = () => {
  return <div style={{ height: 1, background: "black", opacity: 0.3 }}></div>;
};

const useStyles = makeStyles((theme: Theme) => ({
  stories: {
    padding: "10px 150px",
    fontWeight: 200,
    marginBottom: 150,
    display: "grid",
    gridTemplateRows: "auto 1px auto 1px auto 1px auto 1px auto",
    gridGap: 15,
    [MOBILE_QUERY]: {
      padding: "0px 50px",
      marginBottom: 50,
    },
  },
  farewell: {
    textAlign: "center",
    background: grey[200],
    borderRadius: 5,
    paddingTop: 20,
    fontSize: "1.2rem",
    height: 100,
    "& .emoji-heart": {
      fontSize: 40,
    },
    [MOBILE_QUERY]: {
      fontSize: "1rem",
      height: 75,
    },
  },
}));

const About = observer(() => {
  const classes = useStyles();

  return (
    <section>
      <div>
        <ImageStrip />
        <div className={classes.stories}>
          <TheresaBackground />
          <FancyBreak />
          <EricBackground />
          <FancyBreak />
          <HowWeMet />
          <FancyBreak />
          <StoryOfProposal />
          <FancyBreak />
          <p className={classes.farewell}>
            We look forward to celebrating with all of you at our wedding in
            Paso Robles, CA in May 2022!
          </p>
        </div>
      </div>
    </section>
  );
});

export default About;
