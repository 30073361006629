import InfoParagraph from "./InfoParagraph";

const TheresaBackground = () => {
  const body = ` Theresa was born in Anaheim, CA. Her family later moved to Ontario, CA
    where she would live throughout most of her life. For college, she
    attended the Fashion Institute of Design and Merchandising in Los Angeles,
    CA where she studied Visual Communications. After receiving a job offer to work as a
    personal stylist in Mission Viejo, CA, she was contacted by a recruiter for Nordstrom that made her
    an offer as a Product Stylist for Nordstrom at the Photo Studio.  She would go on to accept the offer with
    Nordstrom where she would meet her future husband just a few months after starting.`;
  return <InfoParagraph body={body} title="About Theresa" />;
};

export default TheresaBackground;
