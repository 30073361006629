import { useState } from "react";
import { Drawer, IconButton, makeStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CustomLink from "Components/CustomLink";
import Names from "assets/graphics/names_black.png";

interface StyleInput {
  open: Boolean;
}

const useStyles = makeStyles({
  paper: {
    background: "rgba(255, 255, 255, 0.9)",
  },
  icon: {
    marginLeft: ({ open }: StyleInput) => (open ? 50 : 0),
    marginBottom: "auto",
  },
  linkContainer: {
    textAlign: "center",
  },
  header: {
    display: "flex",
  },
  imgContainer: {
    padding: 5,
    display: "flex",
    justifyContent: "center",
    width: "80%",
  },
  logo: {
    height: 50,
    width: 85,
  },
});

const MobileNav = () => {
  const [open, setIsOpen] = useState(false);
  const classes = useStyles({ open });

  function handleClick() {
    setIsOpen(!open);
  }

  function onClose() {
    setIsOpen(false);
  }
  return (
    <>
      <div className={classes.header}>
        <IconButton onClick={handleClick} className={classes.icon}>
          <MenuIcon />
        </IconButton>
        <div className={classes.imgContainer}>
          <img className={classes.logo} src={Names} alt="Fail" />
        </div>
      </div>
      <Drawer
        open={open}
        anchor={"left"}
        onClose={onClose}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.linkContainer}>
          <CustomLink to={"/"} text={"Home"} />
          <CustomLink to={"/about"} text={"About Us"} />
          <CustomLink to={"/venue"} text={"Venue"} tooltipPlacement="right" />
          <CustomLink
            to={"/registry"}
            text={"Registry"}
            tooltipPlacement="right"
          />
        </div>
      </Drawer>
    </>
  );
};

export default MobileNav;
