import { makeStyles } from "@material-ui/core";
import CustomLink from "../CustomLink";
import { MOBILE_QUERY } from "AppContstants";
import Names from "assets/graphics/names_black.png";

const useStyles = makeStyles({
  NavLinks: {
    display: "grid",
    gridTemplateColumns: "repeat(5, auto)",
    textAlign: "center",
    margin: "auto",
    padding: 25,
    width: 800,
    [MOBILE_QUERY]: {
      gridTemplateColumns: "50px",
      gridTemplateRows: "repeat(4, auto)",
      height: 250,
      background: "none",
    },
    minWidth: 150,
  },
  logo: {
    height: 120,
    width: 175,
  },
});

const NavLinks = () => {
  const classes = useStyles();
  return (
    <section className={classes.NavLinks}>
      <CustomLink to={"/"} text={"Home"} />
      <CustomLink to={"/about"} text={"About Us"} />
      <img className={classes.logo} src={Names} alt="Fail" />
      <CustomLink to={"/venue"} text={"Venue"} />
      <CustomLink to={"/registry"} text={"Registry"} />
    </section>
  );
};

export default NavLinks;
