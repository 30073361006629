import { makeAutoObservable } from "mobx";
import { LinkType } from "types/LinkType";

class MainStore {
  links: Array<LinkType> = [
    {
      title: "Allegretto",
      url: "https://www.allegrettovineyardresort.com/?gclid=CjwKCAjwhYOFBhBkEiwASF3KGQaWpIWtJ32dpgpqRwyn7zhXXYRLqFiJ3qCFVFmWzeoRVezrab1V0xoCY-oQAvD_BwE&gclsrc=aw.ds",
    },
  ];
  images: Array<string> = [];

  constructor() {
    makeAutoObservable(this);
  }

  addImages(images: Array<string>) {
    if (!Array.isArray(images)) return;
    this.images = images;
  }
}

export default new MainStore();
