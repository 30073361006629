import { useState } from "react";
import type { ReactElement } from "react";
import { Tooltip } from "@material-ui/core";
import ToolTipBody from "./ToolTipBody";

interface MobileToolTipProps {
  children: ReactElement<any, any>;
}

const MobileToolTip = (props: MobileToolTipProps) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);

  let autoCloseTimeout: ReturnType<typeof setTimeout>;

  function handleOpen() {
    if (autoCloseTimeout) clearTimeout(autoCloseTimeout);
    setIsOpen(true);

    autoCloseTimeout = setTimeout(() => {
      setIsOpen(false);
    }, 1500);
  }

  return (
    <Tooltip
      open={isOpen}
      onClick={handleOpen}
      placement="right"
      title={<ToolTipBody />}
    >
      {children}
    </Tooltip>
  );
};

export default MobileToolTip;
