import { makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import LandingImage from "assets/images/landing.jpg";

const useStyles = makeStyles({
  Landing: {
    height: "100%",
    backgroundColor: "white",
    background: `${grey[300]} url(${LandingImage}) no-repeat center padding-box`,
    backgroundPosition: "calc(50% + 38px) 50%",
    overflow: "hidden",
  },
});

const Landing = () => {
  const classes = useStyles();
  return <section className={classes.Landing}></section>;
};

export default Landing;
