import InfoParagraph from "./InfoParagraph";

const StoryOfProposal = () => {
  const body = `Eric decided late 2019 that he wanted to propose to Theresa.  He reached out
  to close friends of Theresa's, asking them for help to find the perfect ring.  The first plan
  was to propose during their trip to Italy in March 2020. Unfortunately, due to the pandemic 
  of COVID-19, they were unable to take their trip. Finally, in February 2021, Eric could not
  wait any longer and started putting together a plan.  He took her to the Huntington Library 
  in San Marino, CA. It is Theresa's favorite spot in LA because it has beautiful gardens.  Due
  to the lockdown, Eric did not have an opportunity to check out the grounds to plan out exactly
  where he was going to propose, so he had to improvise a spot when it seemed right.  After strolling
  through the grounds and waiting a long time for a moment when there was not a large crowd,
  Eric propsed near the big fountain on February 13th, 2021. Since you're reading this, you can guess that
  she said "Yes!".`;
  return <InfoParagraph body={body} title="Proposal" />;
};

export default StoryOfProposal;
