import { makeStyles } from "@material-ui/core";
import moment from "moment";
import cn from "classnames";
import { LAUNCH_DATES, MOBILE_QUERY } from "AppContstants";

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: 15,
    fontSize: "0.9rem",
    "& .tooltip-title": {
      fontSize: "0.8rem",
      paddingBottom: 6,
      textAlign: "center",
    },
    [MOBILE_QUERY]: {
      height: 25,
      padding: 5,
      paddingBottom: 10,
      fontSize: "0.7rem",
      "& .tooltip-title": {
        fontSize: "0.6rem",
        paddingBottom: 3,
        textAlign: "center",
      },
    },
  },
  root: {
    [MOBILE_QUERY]: {
      height: 25,
    },
  },
}));

const ToolTipBody = () => {
  const classes = useStyles();
  function createToolTipMsg(): String {
    const now = moment();
    const dayDiff = LAUNCH_DATES.diff(now, "days");
    return `Check back in ${dayDiff} days!`;
  }
  return (
    <div className={cn(classes.tooltip, classes.root)}>
      <div className="tooltip-title">Coming Soon!</div>
      {createToolTipMsg()}
    </div>
  );
};

export default ToolTipBody;
