import { createContext, useContext } from "react";

import type Node from "react";

import MainStore from "./MainStore";

const MainContext = createContext(MainStore);

type MainProviderProps = {
  children: Node;
};

export const MainProvider = ({ children }: MainProviderProps) => {
  return (
    <MainContext.Provider value={MainStore}>{children}</MainContext.Provider>
  );
};

export const useMainStore = () => {
  return useContext(MainContext);
};
