import { makeStyles, Theme } from "@material-ui/core";
import LinkButton from "Components/LinkButton";

import Allegretto from "assets/images/allegretto.png";

const useStyles = makeStyles((theme: Theme) => ({
  Venue: {
    display: "grid",
    gridTemplateRows: "repeat(4, auto)",
  },
  body: {
    padding: 10,
    display: "grid",
    gridTemplateRows: "75px auto",
    placeContent: "center",
    color: theme.palette.grey[700],
    marginBottom: 30,
  },
  imageContainer: {
    placeSelf: "center",
  },
  image: {
    width: 700,
    borderRadius: 5,
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      width: 500,
    },
    [theme.breakpoints.down(700)]: {
      margin: "auto",
      width: 400,
    },
  },
  title: {
    placeSelf: "center",
    fontSize: "2.3rem",
    fontWeight: 200,
    fontFamily: "'WindSong', serif",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.0rem",
    },
    [theme.breakpoints.down(700)]: {
      fontSize: "1.5rem",
    },
  },
  text: {
    textAlign: "center",
    maxWidth: 720,
    letterSpacing: 3,
    lineHeight: 2,
    [theme.breakpoints.down(700)]: {
      fontSize: "0.9rem",
    },
  },
  buttonContainer: {
    placeSelf: "center",
    display: "grid",
    marginBottom: 100,
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(3, auto)",
      gridGap: 50,
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "repeat(3, auto)",
      gridGap: 15,
    },
  },
  linksTitle: {
    placeSelf: "center",
    fontSize: "1.7rem",
    marginBottom: 20,
    color: theme.palette.grey[700],
    fontWeight: 200,
    fontFamily: "'WindSong', serif",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.0rem",
    },
    [theme.breakpoints.down(700)]: {
      fontSize: "1.5rem",
    },
  },
}));

const Venue = () => {
  const classes = useStyles();

  return (
    <section className={classes.Venue}>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          src={Allegretto}
          alt="Sherlock and Odin"
        />
      </div>
      <div className={classes.body}>
        <h3 className={classes.title}>Allegretto Vineyard & Resort</h3>
        <p className={classes.text}>
          Spread across 20 acres of vineyards, olive and fruit trees, and
          gardens to stroll around in, this sprawling wine country resort in
          Paso Robles, California is reminiscent of an Italian villa. We fell in
          love immediately once we pulled up to the resort and knew this place
          was special. Theresa has also always dreamt of going to Italy so what
          better place to get married in California than this Tuscan inspired
          venue. It's location also puts guests near the region's hundreds of
          wineries, so you'll be sure to have a great weekend with so much to
          do!
        </p>
      </div>
      <div className={classes.linksTitle}>Accomodation Links Below</div>
      <div className={classes.buttonContainer}>
        <LinkButton
          title="Allegretto"
          link="https://www.allegrettovineyardresort.com/"
        />
        <LinkButton
          title="La Quinta Inn"
          link="https://www.wyndhamhotels.com/laquinta/paso-robles-california/la-quinta-paso-robles/overview?CID=LC:LQ::GGL:RIO:National:52816&iata=00093796"
        />
        <LinkButton title="Adelaide Inn" link="https://www.adelaideinn.com/" />
      </div>
    </section>
  );
};

export default Venue;
