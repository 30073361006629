import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  InfoParagraph: {
    padding: "0px 25px",
    borderRadius: 10,
    textAlign: "center",
  },
  title: {
    fontSize: "2.1rem",
    opacity: 0.7,
    fontWeight: 200,
    fontFamily: "'WindSong', serif",
  },
  titleText: {
    borderBottom: `2px solid #698a6f`,
  },
  body: {
    color: theme.palette.grey[700],
    letterSpacing: 3,
    lineHeight: 2,
  },
}));

interface InfoParagraphProps {
  body: string;
  title: string;
}

const InfoParagraph = (props: InfoParagraphProps) => {
  const { body, title } = props;
  const classes = useStyles();
  return (
    <div className={classes.InfoParagraph}>
      <p className={classes.title}>
        <span className={classes.titleText}>{title}</span>
      </p>
      <p className={classes.body}>{body}</p>
    </div>
  );
};

export default InfoParagraph;
