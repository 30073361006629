import { makeStyles, Button, Theme } from "@material-ui/core";

type Props = {
  title: string;
  link: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  LinkButton: {
    borderRadius: 45,
    width: 200,
    background: theme.palette.grey[700],
    color: "white",
    maxHeight: 36,
  },
}));

const LinkButton = (props: Props) => {
  const { title, link } = props;
  const classes = useStyles();

  function handleClick() {
    window.open(link, "_blank");
  }
  return (
    <Button
      variant="contained"
      className={classes.LinkButton}
      onClick={handleClick}
    >
      {title}
    </Button>
  );
};

export default LinkButton;
