import { useMediaQuery, useTheme } from "@material-ui/core";
import NavLinks from "./NavLinks";
import MobileNav from "./MobileNav";

const NavBar = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));
  return isSmall ? <MobileNav /> : <NavLinks />;
};

export default NavBar;
